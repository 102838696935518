<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-29 17:25:31
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-13 18:45:49
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\recruit\Recruit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main">
      <SiteTopbar/>
      <div class="headers-imgss"></div>
    <div class="container">
    <div class="title-img">
      <img src="../../assets/imgs/TKK/jianone.png" width="569px" height="168px">
    </div>

    <div class="cont">
        <div class="cont-left">
            <div class="cont-img">
                <img src="../../assets/imgs/TKK/jiantwo.png" width="337px" height="92px">
            </div>
        <div class="cont-li">
            <ul>
              
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>画3D，单个计费，累加，无上限</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>轻松月入10000+</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>图哐哐平台100万+高额奖励</p>
                </li>
            </ul>
        </div>
        </div>
        <div class="cont-right">
                <img src="../../assets/imgs/TKK/jianfour.png" width="435px" height="300px">
        </div>
    </div>

     <div class="cont">
        <div class="cont-left">
           <img src="../../assets/imgs/TKK/jianfive.png" width="435px" height="300px">
        </div>
        <div class="cont-right">
                 <div class="cont-img">
                <img src="../../assets/imgs/TKK/jiansever.png" width="337px" height="142px">
            </div>
        <div class="cont-li">
            <ul>
                <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>3D作品获得更多曝光和用户使用下载</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>优秀工程师可获得图哐哐平台签约工程师</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>优先开通图哐哐新功能，获得更多展示机会和渠道</p>
                </li>
                <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>不定时设计大赛，高额奖金等你来领</p>
                </li>
              
            </ul>
        </div>
        </div>
    </div>

    <div class="cont">
        <div class="cont-left">
            <div class="cont-img">
                <img src="../../assets/imgs/TKK/jianeight.png" width="337px" height="92px">
            </div>
        <div class="cont-li">
            <ul>
                <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>每月可设计项目1000+，稳定可靠</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>设计行业风格多样，随心挑选</p>
                </li>
                 <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>时间宽松、自由，边玩边工作</p>
                </li>
                <li>
                    <img src="../../assets/imgs/TKK/jianthree.png" width="30px" height="30px">
                    <p>海量3D模型素材供参考，创意来源无限</p>
                </li>
            </ul>
        </div>
        </div>
        <div class="cont-right">
                <img src="../../assets/imgs/TKK/jiansix.png" width="435px" height="300px">
        </div>
    </div>
    <div class="title-img">
      <img src="../../assets/imgs/TKK/jiannine.png" width="269px" height="168px">
    </div>

    <div class="process">
        <div class="process-text">
            <img src="../../assets/imgs/TKK/jianten.png" width="250px" height="250px">
            <p>点击加入实名验证</p>
        </div>
        <div>
            <img src="../../assets/imgs/TKK/jiantou.png" width="49px" height="30px">
        </div>
         <div class="process-text">
            <img src="../../assets/imgs/TKK/zhione.png" width="250px" height="250px">
            <p>认证完成领取任务</p>
        </div>
        <div>
            <img src="../../assets/imgs/TKK/jiantou.png" width="49px" height="30px">
        </div>
         <div class="process-text">
            <img src="../../assets/imgs/TKK/zhitwo.png" width="250px" height="250px">
            <p>任务评级自主体现</p>
        </div>
    </div>

    <div class="title-img">
      <img src="../../assets/imgs/TKK/zhithree.png" width="509px" height="168px">
    </div>

    <div class="addto">
        <div class="addto-left">
            <img src="../../assets/imgs/TKK/yonghu6.png" width="180px" height="180px">
            <div class="addto-text">
                <p class="addto-one">个人工程师咨询</p>
               
            </div>
        </div>
        <div class="addto-left">
            <img src="../../assets/imgs/TKK/03.png" width="180px" height="180px">
            <div class="addto-text">
                <p class="addto-one">机构入驻咨询</p>
               
            </div>
        </div>
    </div>


<div class="title-img">
      <img src="../../assets/imgs/TKK/zhisix.png" width="269px" height="168px">
    </div>

      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>1.关于入驻。</p>
      </div>
      <div class="content">
          <p class="wen">Q：图哐哐工程师招募主要做什么？</p>
          <p class="da">哐哐：画3D、3D产品设计、制造业技术支持，欢迎有能力的工程师加入。</p>

           <p class="wen">Q：企业、工厂、机构入驻能收获什么？</p>
          <p class="da">哐哐：找产品、找设计师、找创意，产品展示与营销、接单、合作。</p>
           <p class="wen">Q：入驻前后问题咨询谁？</p>
          <p class="da">哐哐：请加如上（个人）（机构）合作微信咨询对接。</p>
      </div>


      <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>2.关于流程。</p>
      </div>
      <div class="content">
          <p class="wen">Q：是否有个人及资质要求？</p>
          <p class="da">哐哐：无任何资质要求，只做身份认定，会CAD软件会画3D即可。</p>

           <p class="wen">Q：是否有固定任务和数量要求，行业限制？</p>
          <p class="da">哐哐：无数量限制，自由时间自由设计，最好是制造行业相关。。</p>
           <p class="wen">Q：设计任务是否有时间限制，没完成有违约金吗？</p>
          <p class="da">哐哐：无时间限制，自由安排，提交成功即可提现，也无违约金。</p>
      </div>


            <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>3.关于稿费。</p>
      </div>
      <div class="content">
          <p class="wen">Q：签约加入是否需要缴纳费用？？</p>
          <p class="da">哐哐：不需要。</p>

           <p class="wen">Q：稿费如何提现</p>
          <p class="da">哐哐：现金、红包，当日提现。</p>
           <p class="wen">Q：任务如何定价？</p>
          <p class="da">哐哐：根据不同任务难易程度定价，尽量合理公平。</p>
           <p class="wen">Q：如何参与大额奖励设计项目？</p>
          <p class="da">哐哐：优秀工程师有机会参与大额奖励项目，详细添加（个人）微信咨询。</p>
          
      </div>
    </div>
    <Footer/>
    <Info/>
 <Cebian/>
  </div>
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'
import Cebian from '@/components/Cebian';
export default {
  name: "recruit",
  components: {
  SiteTopbar,
    Footer,
    Info,
    Cebian
  },
     data(){
    return{
      isOverWX: false
    }
  },
  methods: {
   
    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
this.brief()
  }
};
</script>

<style>
.home-main {
  /* padding-top: 4px; */


}
.page-main {
  /* background: #f5f5f5; */
}
.container {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10vh;
  
}
.headers-imgss{

  width: 100%;
  background-image: url('../../assets/imgs/TKK/jian.png');
  background-repeat:no-repeat;
  height: 602px;
  background-size: 100%;
}
.title-img{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
 .content-title{
   margin-top: 100px;

   height: 50px;

   background-size: 100%;
   background-position:left;
 }
 .content-title p{
   position: relative;
   top: -26px;
   left: 10px;
   color: rgba(69, 77, 89, 1);
font-size: 32px;
font-weight: 700;
 }
 .content{

margin-left: 10px;
color: #000;
font-size: 20px;

line-height: 50px;
font-weight: 400;

 }
 .wen{
     color: rgba(91, 132, 255, 1);
letter-spacing: 1px;
font-size: 24px;
line-height: 60px;
font-weight: 400;
 }
 .da{
     color: rgba(0, 0, 0, 1);
letter-spacing: 1px;
font-size: 24px;
line-height: 60px;
font-weight: 400;
text-indent: 2em;

 }
 li{
     list-style-type: none;
     display: flex;
     align-items: center;

 }
 .cont{
     margin-top: 100px;
     display: flex;
     justify-content: space-between;
 }
 .cont-li{
     margin-top: 50px;
    
 }
 .cont-li li{
     color: rgba(69, 77, 89, 1);
font-size: 24px;
line-height: 40px;
font-weight: 400;
 }
 .process{
 
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 500px;
 }
 .process-text p{
     margin-top: 20px;
     color: rgba(0, 0, 0, 1);
font-size: 24px;
font-weight: 400;
text-align: center;
 }
 .addto{

     height: 400px;
     display: flex;
    justify-content: center;
    align-items: center;
 }
 .addto-left{
     width: 100%;
     text-align: center;
 }
 .addto-left img{
     border-radius: 10px;
     padding: 8px;
     background: rgba(91, 132, 255, 1);
 }
 .addto-text p{
     margin-top: 10px;
 }
 .addto-text{
     
color: rgba(0, 0, 0, 1);
font-size: 18px;
line-height: 26px;
font-weight: 400;
 }
 
 .addto-one{
color: rgba(0, 0, 0, 1);
font-size: 24px;
line-height: 26px;
font-weight: 400;
 }



</style>